import React from "react"

import Button from "../../components/shared/button/button"
import "./404.scss"

const not_found = (props) => (

    <section className="not-found-container">
        <div className="not-found-elements">
            <div className="not-found-title-container">
                <h2 className="not-found-title"> {props.title} </h2>
            </div>
            <div className="not-found-redirect-container">
                <p className="not-found-redirect-description"> {props.description}</p>
                <Button className="not-found-redirect-button" type="A" title={props.linkDescription} link="/"></Button>
            </div>
        </div>


    </section>
);

export default not_found;