import React from 'react'
import PropTypes from 'prop-types'
import anime from 'animejs/lib/anime.es.js';
import "./button.scss"

function Button(props) {

    const animateButton = (target, scale, duration, elasticity) => {
        anime.remove(target);
        anime({
            targets: target,
            scale: scale,
            duration: duration,
            elasticity: elasticity
        });
    }

    const enterButton = (event) => {
        const target = event.target;
        if (!target.disabled) {
            animateButton(target, 1.2, 800, 400);
        }
    };

    const leaveButton = (event) => {
        const target = event.target;
        if (!target.disabled) {
            animateButton(target, 1.0, 600, 300);
        }
    };

    switch (props.type) {
        case "A":
            return (
                <a onMouseEnter={enterButton} onMouseLeave={leaveButton} className={"btn " + props.classNameProps} onClick={props.clickHandler} href={props.link} target={props.openExternal ? "_blank" : "_self"} disabled={props.disabled}>
                    {props.title}
                </a>
            )
        case "B":
            return (

                <button onMouseEnter={enterButton} onMouseLeave={leaveButton} onClick={props.clickHandler} className={"btn " + props.classNameProps} disabled={props.disabled}>
                    {props.title}
                </button>
            )
        default: {

        }
    }
}

Button.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    link: PropTypes.string,
    disabled: PropTypes.bool,
    openExternal: PropTypes.bool,
    classNameProps: PropTypes.string,
    clickHandler: PropTypes.func
}

Button.defaultProps = {
    title: ``,
    type: `B`,
    link: ``,
    disabled: false,
    openExternal: false,
    classNameProps: ``,
    clickHandler: () => { }
}

export default Button

