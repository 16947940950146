import React from "react"

import Layout from "../components/layout"
import SEO from "../components/shared/seo"

import NotFound from "../components/404/404"

const NotFoundPage = () => (
	<Layout>
		<SEO lang="es" title="Página no encontrada." meta={[
			{
				property: `og:type`,
				content: `website`,
			},
			{
				property: `og:image`,
				content: 'https://res.cloudinary.com/spheredevs/image/upload/v1600463996/SphereDevs/not-found-image.jpg'
			},
			{
				property: `og:locale`,
				content: 'es_CR',
			},
		]} />
		<NotFound title="Oops! parece que no encontramos la página que buscabas." 
				  description="Por favor, vuelve a intentarlo (:"
				  linkDescription="Ir al incio">
		</NotFound>
	</Layout>
)

export default NotFoundPage
